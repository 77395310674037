.autocomplete-dropdown-container {
    overflow: hidden;
    border-radius: $border-radius;
    border: 1px solid #c4ecff;
    border-top: none;
    position: absolute;
    background: #fff;
    z-index: 99;

    &:empty {
        padding: 0;
        border-color: transparent;
    }

    .autocomplete-dropdown-loading {
        padding: 9.6px 20px;
        & > svg {
            margin-right: 8px;
        }
    }

    .suggestion-item,
    .suggestion-item--active {
        padding: 9.6px 20px;
        & > span > svg {
            margin-right: 8px;
        }
    }
    .suggestion-item--active:hover {
        background-color: #fafafa;
        cursor: pointer;
    }
}
