.thread-action-dialog {
    .ant-modal-content {
        .ant-modal-body {
            .ant-modal-confirm-body-wrapper::after {
                display: none;
            }
            .ant-modal-confirm-btns {
                display: none;
            }
        }
    }
}
