.auth-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background: #fafafa;

    .auth-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        flex-grow: 1;

        .auth-bg-wrapper {
            position: absolute;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            overflow: hidden;
            pointer-events: none;
            user-select: none;

            .auth-bg-container {
                display: block;
                position: absolute;
                left: 50%;
                height: 188vh;
                width: 183vh;
                border: 1px solid #f2eeee;
                border-radius: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                padding: 18vh;
                margin-left: -25vh;

                span {
                    display: block;
                    height: 100%;
                    width: 100%;
                    border: 1px solid #f2eeee;
                    border-radius: 50%;
                    padding: 18vh;
                }
            }
        }

        .auth-form-wrapper {
            width: 420px;
            padding: 0 35px;

            .ant-card {
                .ant-typography,
                .ant-input,
                .ant-input-affix-wrapper,
                .ant-checkbox-wrapper {
                    color: #848c91;
                }
            }
        }

        .auth-logo {
            height: 90px;
            margin: 0 auto 31px;
            display: block;
        }

        .auth-checkbox-form-item {
            padding-left: 4px;

            .ant-form-item-control-input {
                min-height: 22px;
            }
        }

        .auth-radio-buttons {
            &.ant-radio-group {
                display: flex;

                .ant-radio-button-wrapper {
                    flex-grow: 1;
                    text-align: center;
                }
            }
        }

        .auth-link {
            position: relative;
            display: inline-block;
            padding-bottom: 2px;

            &::after {
                content: '';
                width: 0%;
                height: 1px;
                background: #a2d9f5;
                position: absolute;
                left: 50%;
                bottom: 0;
                transform: translateX(-50%);
                opacity: 0;
                transition: width 0.3s ease-in-out;
            }

            &:hover::after {
                width: 95%;
                opacity: 1;
            }
        }
    }

    .auth-footer {
        margin-top: auto;
        padding: 0 55px 30px;
        display: flex;
        flex-direction: column;
        color: #999999;
        font-size: 14px;
        letter-spacing: -0.4px;
        z-index: 2;

        .auth-footer-version {
            font-weight: 300;
            align-self: flex-start;
            margin-bottom: 12px;
            background-color: #85df8c;
            border-radius: 3px;
            padding: 7px 13px;
            text-align: center;
            color: #5db264;
            font-size: 12px;
        }
        div {
            font-weight: 300;
            a {
                color: #343434;
                text-decoration: none;
                transition: color 0.3s;
                position: relative;
                padding-bottom: 2px;

                &::after {
                    content: '';
                    opacity: 0;
                    width: 0%;
                    height: 1px;
                    background: #cccccc;
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    transition: width 0.3s ease-in-out;
                }

                &:hover {
                    color: lighten(#343434, 20%);

                    &::after {
                        opacity: 1;
                        width: 95%;
                    }
                }
            }
        }
    }
}
