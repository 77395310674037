.main-layout-container {
    height: 100vh;
    max-height: 100vh;

    &.main-layout-sider-right {
        .ant-layout-sider.ant-layout-sider-dark {
            &.ant-layout-sider-fixed {
                right: 0;
                max-height: 100vh;
                overflow: hidden;
            }
            .ant-layout-sider-header {
                background-color: $primary-color;
            }
            &.ant-layout-sider {
                background: #fff;
                border-left: 1px solid #eee;
            }
        }
        &.main-layout-sider-right-collapsed {
            .ant-layout {
                margin-right: 0;
                .ant-layout-header-fixed {
                    width: 100%;
                }
            }
            .main-layout-sider-notifications {
                a {
                    opacity: 0;
                    transition: background-color 0.3s, opacity 0.1s;
                    transition-delay: 0s, 0s;
                }
            }
        }
        &:not(.main-layout-sider-right-collapsed) .ant-layout {
            margin-right: $sidebar-width;
        }
        .ant-layout {
            transition: margin-right 0.2s ease;
        }
        .main-layout-sider-notifications {
            height: calc(100% - #{$header-height});
            margin-top: -14px;
        }
    }
    &.main-layout-sider-left {
        .ant-layout-sider.ant-layout-sider-dark {
            &.ant-layout-sider-fixed {
                left: 0;
            }
        }
        &.main-layout-sider-left-collapsed .ant-layout {
            margin-left: $sidebar-width-collapsed;

            .ant-layout-header-fixed {
                width: calc(100% - #{$sidebar-width-collapsed});
            }
        }
        &:not(.main-layout-sider-left-collapsed) .ant-layout {
            margin-left: $sidebar-width;
        }
        .ant-layout {
            transition: margin-left 0.2s ease;
        }
    }

    .ant-layout-sider.ant-layout-sider-dark {
        color: #fff;

        &.ant-layout-sider-fixed {
            overflow: auto;
            height: 100vh;
            position: fixed;
        }

        .ant-layout-sider-header {
            font-weight: 400;
            font-size: 20px;
            margin-bottom: 14px;
            text-align: center;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            transition: font-size 0.2s ease-in-out;
            span {
                padding: 0 4px;
            }
        }
        &.ant-layout-sider-collapsed {
            .ant-layout-sider-header {
                font-size: 13px;
                flex-direction: column;
                span {
                    padding: 0;
                }
            }
        }
    }

    .ant-layout-header {
        box-shadow: 0px 2px 2px 1px rgba(0, 0, 0, 0.04);
        display: flex;

        &.ant-layout-header-fixed {
            position: fixed;
            width: calc(100% - #{$sidebar-width});
            z-index: 1;
            transition: width 0.2s ease;

            & + .ant-layout-content {
                margin-top: $header-height;
            }
        }
    }

    .ant-layout-content {
        padding: 14px 16px;
        max-height: 100%;
        overflow-y: auto;
    }
}
