@include font-face(Acrom, '../../fonts/Acrom/Acrom-Light', 300);
@include font-face(Acrom, '../../fonts/Acrom/Acrom-Regular', 400);
@include font-face(Acrom, '../../fonts/Acrom/Acrom-Medium', 500);
@include font-face(Acrom, '../../fonts/Acrom/Acrom-Bold', 700);
@include font-face(Acrom, '../../fonts/Acrom/Acrom-ExtraBold', 800);

@include font-face(Gilroy, '../../fonts/Gilroy/Gilroy-Light', 300, null, ttf);
@include font-face(Gilroy, '../../fonts/Gilroy/Gilroy-Regular', 400, null, ttf);
@include font-face(Gilroy, '../../fonts/Gilroy/Gilroy-Medium', 500, null, ttf);
@include font-face(Gilroy, '../../fonts/Gilroy/Gilroy-Bold', 700, null, ttf);
@include font-face(Gilroy, '../../fonts/Gilroy/Gilroy-ExtraBold', 800, null, ttf);

@include font-face(HelveticaNeue, '../../fonts/HelveticaNeue/HelveticaNeue-Light', 300, null, eot woff ttf);
@include font-face(HelveticaNeue, '../../fonts/HelveticaNeue/HelveticaNeue-Regular', 400, null, eot woff ttf);
@include font-face(HelveticaNeue, '../../fonts/HelveticaNeue/HelveticaNeue-Medium', 500, null, eot woff ttf);
@include font-face(HelveticaNeue, '../../fonts/HelveticaNeue/HelveticaNeue-Bold', 700, null, eot woff ttf);
@include font-face(HelveticaNeue, '../../fonts/HelveticaNeue/HelveticaNeue-ExtraBold', 800, null, eot woff ttf);
