@import 'partials/variables';
@import 'partials/mixins';
@import 'partials/fonts';
@import 'partials/form';
@import 'partials/layout';
@import 'partials/thread';
@import 'partials/chart';
@import 'partials/card';
@import 'partials/contexify';
@import 'partials/placesAutocomplete';

body {
    font-family: $font-family;
}

span.delete-wrapper:hover + .ant-image .ant-image-mask {
    opacity: 1;
}
.btn-hover:hover {
    opacity: 0.8;
}

.zone-tabs .ant-tabs {
    visibility: visible !important;
    overflow: unset !important;
}

#followupTabs {
    .ant-tabs-tab {
        padding: 0 !important;
        margin-top: 0 !important;
        display: block;
        width: 100%;
        >div>div {
            padding: 8px 24px;
            text-align: left;
        }
    }
}
