@import '~antd/dist/antd.less';

@layout-sider-header-background: #001529;

@aptos-red-base: #ee87a5;
@aptos-green-base: #a2ebf6;
@aptos-purple-base: #a2ace7;

.custom-button-color(@color, @background, @border, @tint-percent: 0) {
    .button-color(@color, @background, @border);

    &:hover,
    &:focus {
        & when (@tint-percent > 0) {
            .button-color(@color; tint(@background, @tint-percent); tint(@border, @tint-percent));
        }
        & when not (@tint-percent > 0) {
            .button-color(@color; ~`colorPalette('@{background}', 5)`; ~`colorPalette('@{border}', 5)`);
        }
    }
    &:active {
        .button-color(@color; ~`colorPalette('@{background}', 7)`; ~`colorPalette('@{border}', 7)`);
    }
}

.ant-input[disabled],
.ant-input[disabled]:hover {
    border-color: @input-disabled-border;
}

.ant-input-number {
    border-color: @input-border-color;
}

.ant-input-number-lg {
    width: 100%;
}
.empty_dropdown .ant-select-dropdown-empty .ant-select-item-empty{
    color: #343434 !important;
}

.ant-input-lg,
.ant-input-number-lg,
.ant-select-lg {
    font-size: @font-size-base;
}
.ant-input-lg {
    height: @input-height-lg;
}
.ant-input-number-lg input {
    height: @input-height-lg - (@border-width-base * 2);
    padding-top: @input-padding-vertical-lg;
    padding-bottom: @input-padding-vertical-lg;
}

.ant-input-search {
    & > .ant-input-group {
        & > .ant-input-group-addon:last-child {
            .ant-input-search-button:not(.ant-btn-primary) {
                &[disabled] {
                    color: @btn-disable-color;
                }
            }
        }
    }
}

.ant-input-affix-wrapper-lg {
    padding-top: 0;
    padding-bottom: 0;

    .ant-input-lg {
        height: @input-height-lg - (@border-width-base * 2);
    }
}
.ant-input-affix-wrapper-disabled {
    background-color: @input-disabled-bg;
    border-color: @input-disabled-border;
    &:hover {
        border-color: @input-disabled-border;
    }
    .ant-input[disabled] {
        border-color: @input-disabled-border;
        background: @input-disabled-bg;
    }
}
.ant-input-affix-wrapper-disabled .ant-form-item {
    &:not(.ant-form-item-has-error) {
        .ant-input:focus,
        .ant-input-focused,
        .ant-input-affix-wrapper:focus,
        .ant-input-affix-wrapper-focused,
        .ant-input-number:focus,
        .ant-input-number-focused,
        .ant-picker:focus,
        .ant-picker-focused {
            box-shadow: none;
            border-color: @input-hover-border-color;
        }
    }
    &.ant-form-item-has-error {
        .ant-input-number-handler-wrap {
            border-color: @error-color;
        }
    }
}

.ant-input-group-addon {
    .ant-btn:not([disabled]) {
        border-color: @input-border-color;

        &:focus,
        &:hover {
            border-color: @input-hover-border-color;
        }
    }
}

.ant-select-lg {
    width: 100%;

    &.ant-select-focused:not(.ant-select-disabled):not(.ant-select-customize-input) {
        .ant-select-selector {
            box-shadow: none;
            border-color: @input-hover-border-color;
        }
    }
    &.ant-select-multiple {
        .ant-select-selection-item {
            font-size: @font-size-base - 1;
        }
        .ant-select-selection-search {
            height: @select-multiple-item-height-lg + 0.82;
            line-height: @select-multiple-item-height-lg + 0.82;
        }

        .ant-select-selection-item-content,
        .ant-select-selection-item-remove {
            color: #fff;
        }
    }
    &.ant-select-disabled {
        .ant-select-selector {
            border-color: @input-disabled-border;
        }
        &.ant-select-multiple .ant-select-selection-item {
            background-color: lighten(@primary-color, 11%);
            border-color: lighten(@primary-color, 11%);
        }
    }
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    color: @primary-color;
    font-weight: @select-item-selected-font-weight;
}

.ant-checkbox-inner {
    border-radius: 4px;
    border-color: @input-border-color;
}

.ant-form-large {
    &.ant-form-vertical {
        .ant-form-item-label {
            & > label {
                height: @input-height-lg / 2;
            }
        }
    }
}

.ant-form-item-explain,
.ant-form-item-extra {
    padding: 2px 4px;
    font-size: @font-size-base - 1;
    white-space: pre-line;
}
.ant-form-item-with-help {
    margin-bottom: 6px;
}

.ant-radio-button-wrapper-checked {
    &:not([class*=' ant-radio-button-wrapper-disabled']) {
        &.ant-radio-button-wrapper:first-child {
            border-right-color: @btn-primary-bg;
        }
    }
    &:not(.ant-radio-button-wrapper-disabled):first-child,
    &:not(.ant-radio-button-wrapper-disabled) {
        border-color: @btn-primary-bg;
    }
}

.ant-radio-group-solid {
    .ant-radio-button-wrapper-checked {
        &:not(.ant-radio-button-wrapper-disabled) {
            color: #fff;
            background: @btn-primary-bg;
            border-color: @btn-primary-bg;

            &:hover {
                background: @btn-primary-bg;
                border-color: @btn-primary-bg;
            }
        }
    }
}

.ant-picker-large {
    padding: 10.5px 20px 10.5px;
    .ant-picker-input {
        & > input {
            font-size: @font-size-base - 1;
            border-radius: @border-radius-base / 3;
        }
    }
    &.ant-picker-disabled {
        border-color: @input-disabled-border;

        .ant-picker-range-separator {
            color: @input-placeholder-color;
        }
    }
}

.ant-upload {
    &.ant-upload-drag:not(.ant-upload-disabled) {
        border-color: @input-border-color;
        &:hover {
            border-color: @input-hover-border-color;
        }
    }
}

.ant-upload-list-picture-card {
    .ant-upload-list-item {
        padding: 5px;
    }
    .ant-upload-list-item-thumbnail,
    .ant-upload-list-item-thumbnail img {
        object-fit: cover;
        object-position: center;
        border-radius: @border-radius-base;
    }
    .ant-upload-list-item-info::before {
        border-radius: @border-radius-base;
    }
}

.ant-layout-sider-header {
    height: @layout-header-height;
    color: #fff;
    background: @layout-sider-header-background;
}

.ant-layout-sider {
    .ant-menu-inline,
    .ant-menu-vertical,
    .ant-menu-vertical-left {
        border-right-color: @layout-sider-header-background;
    }
    .ant-menu {
        color: #fff;
        background: @layout-sider-background;
    }

    .ant-menu-item:hover,
    .ant-menu-item-active,
    .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
    .ant-menu-submenu-active,
    .ant-menu-submenu-title:hover {
        color: #fff;
        background: @layout-sider-header-background;
    }

    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
        background: @layout-sider-header-background;
    }
    .ant-menu-item-selected {
        color: #fff;
    }
}

.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
    margin-left: 5px;
    margin-right: 5px;
    border-radius: @border-radius-base;

    & > span {
        display: block;
        margin: -@dropdown-vertical-padding -@padding-sm;
        padding: @dropdown-vertical-padding @padding-sm;
    }

    &:hover {
        // background-color: @select-item-active-bg;
        background-color: @primary-color;
        color: #fff;
    }
    a:hover {
        color: #fff;
    }

    &.ant-dropdown-menu-item-disabled,
    &.ant-dropdown-menu-submenu-title-disabled {
        color: rgba(0, 0, 0, 0.25);
        &:hover {
            color: rgba(0, 0, 0, 0.25);
            background-color: #fff;
            cursor: not-allowed;
        }
        & > span {
            pointer-events: none;
        }
    }
}

.ant-table {
    .ant-table-content {
        .ant-table-cell {
            &:not(.ant-table-row-actions-cell) {
                a:not(.ant-btn-primary) {
                    color: #0975e1;
                    &:hover {
                        color: lighten(#0975e1, 20%);
                    }
                }
            }

            .ant-table-cell-status-success {
                color: @green-6;
            }
            .ant-table-cell-status-fail {
                color: @red-5;
            }
        }
        .ant-table-tbody {
            .ant-table-expanded-row > td {
                padding: 6px;
            }
        }
    }
}

.ant-breadcrumb {
    & > span {
        border-bottom: 1px solid rgba(216, 216, 216, 0.5);
        padding-bottom: 10px;

        &:last-child {
            border-color: @primary-color;
        }
    }
    .ant-breadcrumb-link {
        line-height: 3.1;
    }
}

.ant-btn-red {
    .custom-button-color(#fff, @aptos-red-base, @aptos-red-base);
}

.ant-btn-green {
    .custom-button-color(#fff, @aptos-green-base, @aptos-green-base, 18);
}

.ant-btn-purple {
    .custom-button-color(#fff, @aptos-purple-base, @aptos-purple-base, 18);
}

@font-size-sm: 10px;@font-size-base: 12px;@font-size-lg: @font-size-base + 2;@primary-color: #75bee9;@link-color: @primary-color;@text-color: #343434;@heading-color: @text-color;@height-lg: 42px;@border-radius-base: 14px;@card-radius: 20px;@padding-sm: 21px;@input-height-lg: @height-lg - 2;@input-border-color: #c4ecff;@input-hover-border-color: #2690FB;@input-disabled-bg: #FAFAFA;@input-disabled-border: #F0F0F0;@input-disabled-color: @text-color;@input-padding-vertical-lg: (ceil(((@input-height-lg - @font-size-base * @line-height-base) / 2) * 10) / 10) - @border-width-base;@input-number-handler-border-color: @input-hover-border-color;@select-border-color: @input-border-color;@select-single-item-height-lg: @input-height-lg;@select-multiple-item-height-lg: @input-height-lg - 12;@select-selection-item-bg: @primary-color;@select-selection-item-border-color: @primary-color;@select-item-selected-color: @primary-color;@select-item-active-bg: #f0fcff;@select-multiple-item-disabled-border-color: @input-disabled-border;@form-item-margin-bottom: 26px;@form-vertical-label-padding: 0 0 8px 4px;@btn-font-weight: 700;@btn-primary-bg: @input-hover-border-color;@btn-default-border: @btn-primary-bg;@btn-default-color: @btn-primary-bg;@btn-disable-color: #BABABA;@btn-disable-bg: #EFEFEF;@btn-disable-border: @btn-disable-bg;@btn-padding-horizontal-lg: 30px;@layout-body-background: #fafafa;@layout-header-background: #ffffff;@layout-sider-background: #1f2531;@layout-header-padding: 0 16px;@menu-inline-toplevel-item-height: 44px;@menu-item-height: 44px;@menu-item-vertical-margin: 0px;@menu-item-boundary-margin: 0px;@table-header-bg: @btn-disable-bg;@table-header-color: #6d6d6d;@breadcrumb-font-size: 13px;@breadcrumb-last-item-color: @primary-color;